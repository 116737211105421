var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"current":"activatelicence"}},[_c('v-container',[_c('v-card',{staticClass:"card-main",attrs:{"elevation":"0"}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"ma-4",attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('EstimationCard',{attrs:{"loading":_vm.isLoading,"metric-title":"Total number of licences","metricValue":_vm.getTotalNumberOfLicences}})],1),_c('v-col',[_c('EstimationCard',{attrs:{"loading":_vm.isLoading,"metric-title":"Active","metricValue":_vm.numberOfActivatedLicences}})],1),_c('v-col',[_c('EstimationCard',{attrs:{"loading":_vm.isLoading,"metric-title":"Inactive","metricValue":_vm.numberOfInactiveLicences}})],1)],1)],1),_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"loading-text":"Loading... Please wait","headers":_vm.getHeadersDynamically,"items":_vm.flattenedList,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-toolbar-title',{staticClass:"text-h5 font-weight-medium"},[_vm._v("List of licences ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{staticStyle:{"max-width":"450px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","options":_vm.options,"server-items-length":_vm.totalNumberOfLicences},on:{"update:options":function($event){_vm.options=$event}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"reset-text-transform",attrs:{"color":"green","dark":""},on:{"click":function($event){_vm.dialogForLicenceGeneration = true}}},[_vm._v("Generate new licences "),_c('v-icon',[_vm._v(" mdi-plus")])],1)],1)],1)]},proxy:true},{key:"item.activated_at_utc",fn:function(ref){
var item = ref.item;
return [(item.deactivated_at_utc == null)?_c('div',[(
                  item.activated_at_utc && item.deactivated_at_utc == null
                )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{attrs:{"color":!item.activated_at_utc ? 'blue' : 'green',"dark":""}},[_vm._v(" "+_vm._s(item.activated_at_utc ? "Activated" : "Not activated")+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("at "+_vm._s(item.activated_at_utc))])]):_c('v-chip',{attrs:{"color":!item.activated_at_utc ? 'blue' : 'green',"dark":""}},[_vm._v(" "+_vm._s(item.activated_at_utc ? "Activated" : "Not activated")+" ")])],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" Deactivated ")])],1)]}}],null,true)},[_c('span',[_vm._v("at "+_vm._s(item.deactivated_at_utc))])])],1)]}},{key:"item.deeplink_data",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-btn',{staticClass:"reset-text-transform mt-2",attrs:{"color":"black","plain":"","disabled":item.activated_at_utc != false,"text":""},on:{"click":function($event){return _vm.onQRClicked(item)}}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-qrcode")])],1),_c('v-divider',{attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"reset-text-transform mt-2",attrs:{"color":"black","disabled":item.activated_at_utc != false,"plain":"","href":'https://developer.privately.eu/retail-app-activation/' +
                  item.deeplink_data,"target":"_blank","text":""}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-open-in-new")])],1)],1)]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":_vm.snackbarTimeout,"value":false,"absolute":"","top":"","color":!_vm.errorOccurred ? 'success' : 'red'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"reset-text-transform",attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)],1),_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.dialogForQRCode),callback:function ($$v) {_vm.dialogForQRCode=$$v},expression:"dialogForQRCode"}},[_c('v-card',{staticClass:"justify-center"},[_c('v-card-title',{staticClass:"subtitle-1 grey lighten-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Licence code: ")]),_vm._v(" "+_vm._s(_vm.selectedLicenceCode)+" "),_c('br'),_c('span',{staticClass:"subtitle-1",staticStyle:{"overflow-wrap":"break-word","word-wrap":"break-word","word-break":"break-word"}},[_vm._v("Please scan the QR code below to activate your "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("AgeAI for Retail")]),_vm._v(" licence")])]),_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('v-card-text',[_c('qrcode-vue',{attrs:{"value":'https://developer.privately.eu/retail-app-activation/' +
                    _vm.selectedDeeplinkData,"size":300,"level":"H"}})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogForLicenceGeneration),callback:function ($$v) {_vm.dialogForLicenceGeneration=$$v},expression:"dialogForLicenceGeneration"}},[_c('v-card',{attrs:{"loading":_vm.loadingForLicenceGeneration}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Generate new licences")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":[function (v) { return !!v || 'This field is required'; }],"label":"Requested by","required":""},model:{value:(_vm.formInput.requested_by),callback:function ($$v) {_vm.$set(_vm.formInput, "requested_by", $$v)},expression:"formInput.requested_by"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Subclient","rules":[function (v) { return !!v || 'This field is required'; }],"required":""},model:{value:(_vm.formInput.subclient),callback:function ($$v) {_vm.$set(_vm.formInput, "subclient", $$v)},expression:"formInput.subclient"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":[function (v) { return !!v || 'This field is required'; }],"label":"Location","required":""},model:{value:(_vm.formInput.location),callback:function ($$v) {_vm.$set(_vm.formInput, "location", $$v)},expression:"formInput.location"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":[
                      function (v) { return !!v || 'This field is required'; },
                      function (v) { return _vm.emailRegex.test(v) || 'Invalid Email address'; } ],"label":"Billed to (email)","required":""},model:{value:(_vm.formInput.billed_to_email),callback:function ($$v) {_vm.$set(_vm.formInput, "billed_to_email", $$v)},expression:"formInput.billed_to_email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":[
                      function (v) { return !!v || 'This field is required'; },
                      function (v) { return (v && v <= 50 && v >= 1) ||
                        'Quantity must be between 1 and 50'; } ],"label":"Quantity","type":"number","required":""},model:{value:(_vm.formInput.quantity),callback:function ($$v) {_vm.$set(_vm.formInput, "quantity", $$v)},expression:"formInput.quantity"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-select',{attrs:{"items":_vm.priceList,"outlined":"","dense":"","item-text":"priceName","item-value":"priceVal","rules":[function (v) { return !!v.priceVal || 'Please select a price'; }],"label":"Price per licence","return-object":"","single-line":"","required":""},model:{value:(_vm.formInput.price_per_licence),callback:function ($$v) {_vm.$set(_vm.formInput, "price_per_licence", $$v)},expression:"formInput.price_per_licence"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"required":"","rules":[function (v) { return !!v || 'Please select a period'; }],"column":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticStyle:{"font-size":"16px"}},[_vm._v(" Subscription Period:   ")])]},proxy:true}]),model:{value:(_vm.formInput.subscription_period),callback:function ($$v) {_vm.$set(_vm.formInput, "subscription_period", $$v)},expression:"formInput.subscription_period"}},[_c('v-radio',{attrs:{"label":"Monthly","value":"month"}}),_c('v-radio',{attrs:{"label":"Yearly","value":"year"}})],1)],1),(
                    _vm.formInput.quantity > 0 &&
                    _vm.formInput.quantity <= 50 &&
                    _vm.formInput.price_per_licence.priceVal &&
                    _vm.formInput.subscription_period != ''
                  )?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-subheader',{staticStyle:{"font-size":"16px"}},[_vm._v("Estimated price:")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","readonly":"","value":_vm.getEstimatedPrice,"prefix":"$"}})],1)],1):_vm._e(),_c('v-col')],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialogForLicenceGeneration = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"loading":_vm.loadingForLicenceGeneration,"color":"green darken-1","dark":""},on:{"click":_vm.generateLicences}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }