<template>
  <v-app style="background: #fafafa">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12">
          <v-card elevation="0" class="login-card" style="max-height: 100px">
            <v-row justify="center" class="py-8"
              ><img
                class="justify-center"
                :src="require('@/assets/images/privlogo.png')"
                alt="logo"
            /></v-row>
            <v-alert
              style="text-align: center; max-width: 300px"
              dense
              v-if="isActivated"
              class="login-alert mt-10"
              outlined
              text
              type="success"
              >Account activated. Now you can download and login via
              <b>AgeAI for Retail App</b>
            </v-alert>
            <v-alert
              style="text-align: center; max-width: 300px"
              dense
              v-if="showError"
              class="login-alert mt-10"
              outlined
              text
              type="error"
              >{{ errorMsg }}
            </v-alert>
            <v-row
              v-if="!showError"
              justify="center"
              align="center"
              style="text-align: center"
              ><v-col cols="6">
                <a
                  href="https://play.google.com/store/apps/details?id=ch.privately.ageassure.retail"
                  target="_blank"
                  ><v-img
                    contain
                    :src="require('@/assets/images/android.png')"
                    height="200" /></a
              ></v-col>
              <v-col cols="6">
                <v-img
                  contain
                  :src="require('@/assets/images/qr-code.png')"
                  height="115"
                />
                <span style="font-size: 15px"
                  >Scan the QR code to download the
                  <b> AgeAI for Retail</b> app</span
                >
              </v-col>
            </v-row>

            <v-spacer></v-spacer>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-footer absolute style="height: 140px">
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="(link, index) in links"
          elevation="0"
          :key="index"
          color="#18739d"
          text
          dark
          link
          :target="!link.mailto ? '_blank' : ''"
          :href="link.link"
          :ripple="false"
          rounded
          class="my-2 rounded-0"
        >
          {{ link.title }}
        </v-btn>
        <v-col
          class="py-4 text-center black--text font-weight-light subtitle-2"
          cols="12"
        >
          © Copyright 2024 Privately - All Rights Reserved
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import jwt from "jsonwebtoken";

const SECRET = "2msglh0vaaqogn96fig5d5ohfk6v0932dremjhgr5ad5gmg0d7t";

export default {
  components: {},
  created() {
    if (this.token) {
      try {
        const payload = jwt.verify(this.token, SECRET);
        let confirmPayload = {
          email: payload.email,
          password: payload.password,
        };
        this.ConfirmEmail(confirmPayload).then((resp) => {
          console.log(resp);
          if (resp.result == "OK") {
            this.isActivated = true;
          } else {
            this.showError = true;
            this.errorMsg = "Link is invalid or expired";
          }
        });
      } catch (err) {
        console.log(err);
        this.showError = true;
        this.errorMsg = "Link is invalid or expired";
      }
    }
  },
  methods: {
    ...mapActions(["LogIn", "ConfirmEmail", "GetCurrentPlan"]),
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  data() {
    return {
      showIcon: false,
      links: [
        {
          title: "Privacy Policy",
          link: "/static/docs/privacy_policy.pdf",
        },
        {
          title: "Terms and Conditions",
          link: "/static/docs/terms_and_conditions.pdf",
        },
        {
          title: "Contact Us",
          link: "mailto:contact@privately.eu",
          mailto: true,
        },
      ],
      valid: false,
      token: this.$route.params.token,
      isActivated: false,
      emailErr: "",
      showError: false,
      isLoading: false,
    };
  },
};
</script>