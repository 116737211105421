<template>
  <base-layout current="profile">
    <v-container fluid>
      <v-card class="card-main" elevation="0">
        <div>
          <v-row class="px-2 py-4"
            ><v-card elevation="0" max-width="400" color="transparent">
              <v-list-item>
                <v-list-item-content>
                  <v-subheader class="text-h5">Delete Your Account</v-subheader>
                </v-list-item-content>
              </v-list-item>
              <div class="px-4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Session ID</v-list-item-title>
                    <v-list-item-subtitle>{{ sessionId }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Email</v-list-item-title>
                    <v-list-item-subtitle>{{ user }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line v-if="currentPlan">
                  <v-list-item-content>
                    <v-list-item-title>Current Plan</v-list-item-title>
                    <v-list-item-subtitle style="text-transform: capitalize">{{
                      currentPlan
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  two-line
                  v-if="userData.session_details.monthly_limit"
                >
                  <v-list-item-content>
                    <v-list-item-title>Monthly Limit</v-list-item-title>
                    <v-list-item-subtitle>{{
                      userData.session_details.monthly_limit
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  two-line
                  v-if="userData.session_details.register_time"
                >
                  <v-list-item-content>
                    <v-list-item-title>Registration Date</v-list-item-title>
                    <v-list-item-subtitle>{{
                      userData.session_details.register_time
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line @click="$router.push(accountDeleteRoute)">
                  <v-list-item-content>
                    <v-list-item-title style="color: red"
                      >Delete Your Account</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-card></v-row
          >
        </div>
      </v-card>
    </v-container>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
import store from "@/store";
export default {
  beforeMount() {
    this.userData = store.getters.StateUserData;
    this.user = store.getters.StateUser;
    this.sessionId = store.getters.StateSessionId;
    this.secretKey = "ab36cf-33daaeef-3123abdec-3112345"; // TODO
    this.currentPlan = store.getters.StateSessionDetails.session_type;
    console.log(this.userData);
  },
  components: {
    BaseLayout,
  },
  methods: {},
  data() {
    return {
      userData: "",
      accountDeleteRoute: "/account/delete",
      sessionId: "",
      user: "",
      currentPlan: "",
      showSecret: false,
      snackbar: false,
      text: "Copied!",
      timeout: 2000,
    };
  },
};
</script>