var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"current":"apikeys"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"card-main",attrs:{"elevation":"0"}},[_c('v-form',[_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"absolute":"","right":"","color":"green","text":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('v-row',{staticClass:"px-8 py-8"},[_c('v-col',{attrs:{"cols":"10","xl":"2","lg":"2"}},[_c('v-subheader',{staticClass:"text-h5"},[_vm._v("API Key")])],1),_c('v-col',{attrs:{"cols":"10","xl":"4","lg":"4"}},[_c('v-text-field',{staticClass:"rounded-0",staticStyle:{"font-family":"'Courier New', monospace"},attrs:{"id":"api-key","filled":"","outlined":"","readonly":""},model:{value:(_vm.apiKey),callback:function ($$v) {_vm.apiKey=$$v},expression:"apiKey"}})],1),_c('v-icon',{attrs:{"medium":"","ripple":false},on:{"click":function($event){return _vm.copyKey('api-key')}}},[_vm._v("mdi-content-copy")])],1),_c('v-row',{staticClass:"px-8"},[_c('v-col',{attrs:{"cols":"10","xl":"2","lg":"2"}},[_c('v-subheader',{staticClass:"text-h5"},[_vm._v("Secret Key")])],1),_c('v-col',{attrs:{"cols":"10","xl":"4","lg":"4"}},[_c('v-text-field',{staticClass:"rounded-0",staticStyle:{"font-family":"'Courier New', monospace"},attrs:{"filled":"","outlined":"","append-icon":_vm.showSecret ? 'mdi-eye' : 'mdi-eye-off',"readonly":"","id":"secret-key","ripple":false,"type":_vm.showSecret ? 'text' : 'password'},on:{"click:append":function($event){_vm.showSecret = !_vm.showSecret}},model:{value:(_vm.secretKey),callback:function ($$v) {_vm.secretKey=$$v},expression:"secretKey"}})],1),_c('v-icon',{attrs:{"ripple":false,"medium":""},on:{"click":function($event){return _vm.copyKey('secret-key')}}},[_vm._v("mdi-content-copy")])],1)],1),(
          _vm.userData != null &&
          (_vm.userData.session_details.monthly_limit ||
            _vm.userData.session_details.register_time ||
            _vm.userData.session_details.session_type)
        )?_c('div',[_c('v-divider',{staticStyle:{"border-style":"dashed"}}),_c('v-row',{staticClass:"px-8 py-8"},[_c('v-card',{attrs:{"elevation":"0","max-width":"400"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-subheader',{staticClass:"text-h5"},[_vm._v("Subscription Details")])],1)],1),_c('div',{staticClass:"px-4"},[(_vm.sessionDetails.session_type)?_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Current Plan")]),_c('v-list-item-subtitle',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(_vm.sessionDetails.session_type))])],1)],1):_vm._e(),(
                  _vm.sessionDetails.session_type != 'none' &&
                  (_vm.sessionDetails.limit || _vm.sessionDetails.monthly_limit)
                )?_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Monthly Limit")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.sessionDetails.limit || _vm.sessionDetails.monthly_limit))])],1)],1):_vm._e(),(_vm.userData.session_details.register_time)?_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Registration Date")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.userData.session_details.register_time))])],1)],1):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }