<template>
  <v-app>
    <v-container fluid>
      <v-row style="background-color: #6fdcff11" justify="center">
        <v-col cols="12" md="4"
          ><video-background
            :src="require('@/assets/video/retailer.mp4')"
            style="max-height: 735px; height: 100vh"
          >
            <v-container fluid class="fill-height">
              <v-row justify="center" align="center"
                ><v-col
                  class="text-xs-center"
                  style="text-align: center; line-height: 1.25em"
                >
                  <span class="text-h2 white--text font-weight-bold"
                    >AgeAssure for Retailers</span
                  >
                </v-col>
              </v-row>
            </v-container>
          </video-background></v-col
        >
        <v-col md="8"
          ><v-row class="py-2"
            ><v-col
              ><v-img
                class="mx-auto my-auto"
                width="350px"
                contain
                :src="require('@/assets/images/ic_privately_logo.png')"
              ></v-img></v-col
          ></v-row>

          <v-row justify="center" align="center" class="py-1">
            <v-col md="2">
              <v-img
                width="125px"
                :src="require('@/assets/images/carousel_1.png')"
                contain
            /></v-col>

            <v-col md="6"
              ><v-card elevation="0" color="transparent">
                <v-card-title
                  style="
                    text-align: justify;
                    font-size: 18px;
                    word-break: normal;
                  "
                  class="font-weight-thin"
                  >The AgeAssure application is developed to support retailers
                  who sell age restricted goods.
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-divider inset class="mt-6"></v-divider>

          <v-row justify="center" align="center" class="py-1">
            <v-col md="6"
              ><v-card elevation="0" color="transparent">
                <v-card-title
                  style="
                    text-align: justify;
                    font-size: 18px;
                    word-break: normal;
                  "
                  class="font-weight-thin"
                  >This camera app predicts age range of a person based on their
                  facial features without collecting any information about them.
                </v-card-title>
              </v-card>
            </v-col>
            <v-col md="2">
              <v-img
                width="125px"
                :src="require('@/assets/images/carousel_2.png')"
                contain
            /></v-col>
          </v-row>

          <v-divider inset class="mt-3 mb-3"></v-divider>
          <v-row justify="center" align="center">
            <v-col md="2">
              <v-img
                width="125px"
                :src="require('@/assets/images/carousel_3.png')"
                contain
            /></v-col>

            <v-col md="6"
              ><v-card elevation="0" color="transparent">
                <v-card-title
                  style="
                    text-align: justify;
                    font-size: 18px;
                    word-break: normal;
                  "
                  class="font-weight-thin"
                  >The recommendation of this application may be used by the
                  ground staff to decide wether a physical verification of age
                  through an identity card might be necessary.
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>

          <v-divider inset class="mt-6"></v-divider>

          <v-row justify="center" align="center" class="py-1">
            <v-col md="6"
              ><v-card elevation="0" color="transparent">
                <v-card-title
                  class="font-weight-thin"
                  style="
                    text-align: justify;
                    font-size: 18px;
                    word-break: normal;
                  "
                  >The age assurance technology deployed in this app is
                  certified for (UK)GDPR compliance and performance Challenge 25
                  compliance.
                </v-card-title>
              </v-card>
            </v-col>
            <v-col md="2">
              <v-img
                width="275px"
                :src="require('@/assets/images/ukas_logo.jpg')"
                contain
            /></v-col>
          </v-row>
        </v-col> </v-row
    ></v-container>

    <v-container fluid style="background: #18739d" height="125">
      <v-row justify="center" align="center">
        <v-col xs="12" md="5" lg="5">
          <v-btn
            :to="signup_route"
            block
            x-large
            style="font-size: 18px"
            color="success"
            dark
          >
            SIGN UP FOR A 7-DAY FREE TRIAL NOW!</v-btn
          >
        </v-col>

        <v-col md="1" lg="3" xs="12" style="text-align: center"
          ><span class="text-h3 white--text"> OR </span></v-col
        >
        <v-col xs="12" lg="4" class="">
          <v-row justify="center" align="center"
            ><v-col cols="12" style="text-align: center"
              ><span class="white--text">if you already signed up,</span></v-col
            ></v-row
          >
          <v-row justify="center" align="center" class="py--2"
            ><v-col cols="12">
              <a
                href="https://play.google.com/store/apps/details?id=ch.privately.margin.demo"
                target="_blank"
                ><v-img
                  contain
                  :src="require('@/assets/images/android.png')"
                  height="80" /></a></v-col
          ></v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-footer>
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="link in links"
          elevation="0"
          :key="link"
          color="#18739d"
          text
          dark
          link
          :target="!link.mailto ? '_blank' : ''"
          :href="link.link"
          :ripple="false"
          rounded
          class="my-2 rounded-0"
        >
          {{ link.title }}
        </v-btn>
        <v-col
          class="py-4 text-center black--text font-weight-light subtitle-2"
          cols="12"
        >
          © Copyright 2024 Privately - All Rights Reserved
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  methods: {},
  data: () => ({
    signup_route: "/retail-signup",
    links: [
      {
        title: "Privacy Policy",
        link: "/static/docs/privacy_policy.pdf",
      },
      {
        title: "Terms and Conditions",
        link: "/static/docs/terms_and_conditions.pdf",
      },
      {
        title: "Contact Us",
        link: "mailto:contact@privately.eu",
        mailto: true,
      },
    ],
  }),
};
</script>