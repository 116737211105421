<template>
  <v-app id="inspire" style="background: #fafafa">
    <v-container fill-height>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="#18739d"
      ></v-progress-linear>

      <v-row justify="center" align="center">
        <v-col cols="12" sm="4">
          <div v-if="!showError">
            <v-row>
              <v-col cols="8">
                <img :src="require('@/assets/images/privlogo.png')" alt="logo"
              /></v-col>
            </v-row>
            <v-divider
              style="border-style: dashed; margin-top: 10px"
            ></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-subheader class="text-h5 black--text"
                  >Subscription Details</v-subheader
                >
              </v-list-item-content>
            </v-list-item>
            <div class="px-4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>User:</v-list-item-title>
                  <v-list-item-subtitle>{{ user }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-spacer></v-spacer>
                <v-list-item-content>
                  <v-list-item-title>Selected Plan:</v-list-item-title>
                  <v-list-item-subtitle>{{
                    selected_plan
                  }}</v-list-item-subtitle>
                </v-list-item-content></v-list-item
              >
            </div>
            <v-divider
              style="margin-bottom: 10px; border-style: dashed"
            ></v-divider>
            <label>Card Number</label>
            <div
              id="card-number"
              style="
                border: 1px solid #cecece;
                border-radius: 5px;
                margin-bottom: 10px;
              "
            ></div>
            <label>Card Expiry</label>
            <div
              id="card-expiry"
              style="
                border: 1px solid #cecece;
                border-radius: 5px;
                margin-bottom: 10px;
              "
            ></div>
            <label>Card CVC</label>
            <div
              id="card-cvc"
              style="border: 1px solid #cecece; border-radius: 5px"
            ></div>
            <div id="card-error"></div>

            <v-row align="center" style="margin-top: 5px">
              <v-col cols="4">
                <button
                  style="margin-top: 10px; border-radius: 10px !important"
                  id="custom-button"
                  @click="createToken"
                >
                  Subscribe
                </button></v-col
              >
              <v-spacer></v-spacer>
              <v-col cols="4"
                ><v-img
                  contain
                  width="200px"
                  :src="require('@/assets/images/stripe_black.svg')"
                  alt="logo" /></v-col
            ></v-row>
          </div>
          <div v-else>
            <v-alert type="error"> {{ errorMsg }}</v-alert>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-footer absolute>
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="link in links"
          elevation="0"
          :key="link"
          color="#18739d"
          text
          dark
          link
          :target="!link.mailto ? '_blank' : ''"
          :href="link.link"
          :ripple="false"
          rounded
          class="my-2 rounded-0"
        >
          {{ link.title }}
        </v-btn>
        <v-col
          class="py-4 text-center black--text font-weight-light subtitle-2"
          cols="12"
        >
          © Copyright 2024 Privately - All Rights Reserved
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import jwt from "jsonwebtoken";
import store from "@/store";
import { mapActions } from "vuex";

const SECRET = "2msglh0vaaqogn96fig5d5ohfk6v0932dremjhgr5ad5gmg0d7t";

export default {
  data() {
    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      baseDomain: window.location.origin,
      cardCvc: null,
      loading: false,
      selected_plan: null,
      user: null,
      showError: false,
      errorMsg: "",
      payloadToken: this.$route.params.token,
      links: [
        {
          title: "Privacy Policy",
          link: "/static/docs/privacy_policy.pdf",
        },
        {
          title: "Terms and Conditions",
          link: "/static/docs/terms_and_conditions.pdf",
        },
        {
          title: "Contact Us",
          link: "mailto:contact@privately.eu",
          mailto: true,
        },
      ],
    };
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements();
    },
  },
  mounted() {
    // Style Object documentation here: https://stripe.com/docs/js/appendix/style
    const style = {
      base: {
        color: "black",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "18px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    this.cardNumber = this.stripeElements.create("cardNumber", { style });
    this.cardNumber.mount("#card-number");
    this.cardExpiry = this.stripeElements.create("cardExpiry", { style });
    this.cardExpiry.mount("#card-expiry");
    this.cardCvc = this.stripeElements.create("cardCvc", { style });
    this.cardCvc.mount("#card-cvc");
  },
  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  created() {
    if (this.payloadToken) {
      console.log("success!");
      try {
        const payload = jwt.verify(this.payloadToken, SECRET);
        this.user = payload.user;
        this.selected_plan = payload.selected_plan;
        this.showError = false;
      } catch (err) {
        console.log(err);
        this.showError = true;
        this.errorMsg = "Link is invalid or expired";
      }
    } else {
      console.log("no token");
      this.showError = true;
      this.errorMsg = "Link is invalid or expired";
    }
  },
  methods: {
    ...mapActions(["GetCurrentPlan", "UpdatePaymentSession"]),
    async createToken() {
      this.loading = true;
      const { token, error } = await this.$stripe.createToken(this.cardNumber);
      if (error) {
        this.loading = false;
        // handle error here
        document.getElementById("card-error").innerHTML = error.message;
        return;
      }
      console.log(token);

      // handle the token
      // send it to your server
      await this.UpdatePaymentSession(true);

      let redirectUrl =
        "/payment-success?sessionId=" +
        store.getters.StateSessionId +
        "&selectedPlan=" +
        this.selected_plan;

      this.$router.push(redirectUrl);

      this.loading = false;
    },
  },
};
</script>

<style scoped>
#custom-button {
  height: 40px;
  width: 100px;
  font-weight: bold;
  outline: 1px solid grey;
  background-color: #18739d;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}
</style>